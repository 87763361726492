.mainDiv {position: absolute; color: #fff;  width: 300px; height: auto;  z-index: 999;
     background: #18013a; left: 0; right: 0; margin: 0 auto; padding: 20px; top: 75%;
     border-radius: 5px;
}
.mainDiv p { margin-bottom: 15px; display: inline-block; font-size: 14px;}
.mainDiv p span{ background: #fff; color: #000; padding: 5px 10px;
    border-radius: 30px; font-style: italic;
}

.mainDiv .popdetails{ position: relative;}
.mainDiv h5{ position: absolute;  top: -55px;  right: -31px; width: 32px;
    height: 32px; background: rgb(152, 10, 10); color: #fff;  border-radius: 50%;
    margin: 0;  text-align: center; line-height: 32px; cursor: pointer;}

.dstrdtls{ display: block; margin-top: 15px;  background: #cda052; color: #fff;
    text-align: center; padding: 8px 15px; text-decoration: none;
    margin: 0 auto;margin-top: 0px;margin-top: 30px; border-radius: 20px;
     border: 1px solid #cda052;}
.dstrdtls:hover{ background: #0f0025; border: 1px solid #fff}   
#map{ margin-bottom: 100px; box-shadow: 2px 12px 18px #ccc;}
.mapboxgl-ctrl-attrib{ display: none !important;}